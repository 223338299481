import "./PublicFood.css";
import headerPic from "../../img/publicFood/headerimg.png";
import headerPic2 from "../../img/publicFood/Rectangle 45.png";
import Button from "../Button/Button";
import bacground from "../../img/vector.png";
import comp from "../../img/publicFood/group.png";
import comp2 from "../../img/publicFood/group2.png";
import iphone from "../../img/publicFood/iphone.png";
import icon from "../../img/iconsFastFood/checkarrow.svg";
import handAndPhone from "../../img/publicFood/handandPhone.png";
import Img1 from "../../img/publicFood/Rectangle 60.png";
import Img2 from "../../img/publicFood/Rectangle 49.png";
import Img3 from "../../img/publicFood/Rectangle 50.png";
import Img4 from "../../img/publicFood/Rectangle 49 (1).png";
import Img5 from "../../img/publicFood/Rectangle 50 (1).png";

import icon1 from "../../img/publicFood/check-circle.png";
import icon2 from "../../img/publicFood/Icon.png";
import icon3 from "../../img/publicFood/rocket-2.png";
import icon4 from "../../img/publicFood/cloud.png";
import icon5 from "../../img/publicFood/globewhite.png";
import icon6 from "../../img/publicFood/wifi-slash.png";
import systemIcon1 from "../../img/publicFood/Group 118.png";
import systemIcon2 from "../../img/publicFood/Group 117.png";
import systemIcon3 from "../../img/publicFood/Group 116.png";
import systemIcon4 from "../../img/publicFood/Group 112.png";
import systemIcon5 from "../../img/publicFood/Group 113.png";
import systemIcon6 from "../../img/publicFood/Group 114.png";
import systemIcon7 from "../../img/publicFood/Group 120.png";
import systemIcon8 from "../../img/publicFood/Group 111.png";
import systemIcon9 from "../../img/publicFood/globe.png";
import systemIcon10 from "../../img/publicFood/Group 110.png";
import {useTranslation} from "react-i18next";
import {Swiper_carousel} from "../Swiper_carousel/Swiper_carousel";

function PublicFood({ setIsTrue }) {
    const { t } = useTranslation();
  return (
    <div className="PublicFood_wrapper">
      {/* header */}
      <div className="PublicFood_header">
        <div className="PublicFood_header_text">
          <h1>
            {t("PublicFood_title")} <span>r_keeper</span>{t("ov")}
          </h1>
        </div>
        <div className="PublicFood_header_picture">
          <img id="PublicFood_pic_for_laptop" alt="pic" src={headerPic} />
          <img id="PublicFood_pic_for_mobile" alt="pic" src={headerPic2} />
          <div id="PublicFood_button_component">
            <Button setIsTrue={setIsTrue}/>
          </div>
        </div>
      </div>
      {/*block 2 (same as block3)  */}
      <div className="PublicFood_block2">
        <div className="PublicFood_block" id="PublicFood_block">
          <div className="PublicFood_support_text PublicFood_block2_text">
            <h2>{t("PublicFood_title2")}</h2>
            <p> {t("PublicFood_text1")}</p>
            <p> {t("PublicFood_text1_1")}</p>
            <p> {t("PublicFood_text1_2")}</p>
            <p> {t("PublicFood_text1_3")}</p>
          </div>
          <div className="PublicFood_handAndPhone"> </div>
          <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
        </div>
        <img id="PublicFood_block2_img" alt="phone" src={handAndPhone} />
      </div>
      {/* pic and text flex box */}
      <div className="PublicFood_flex_container">
        <h2>{t("PublicFood_title3")}</h2>
      <div className="PublicFood_flex_box">
        <div className="PublicFood_flex_box__element">
            <div className="PublicFood_flex_text">
                <h4>{t("PublicFood_subtitle1")}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle1_text1")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle1_text2")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle1_text3")}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img1}/>
            </div>
        </div>
        <div className="PublicFood_flex_box__element" id="PublicFood_box__element">
            <div className="PublicFood_flex_text">
                <h4>{t("PublicFood_subtitle2")}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle2_text1")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle2_text2")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle2_text3")}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img2}/>
            </div>
        </div>
        <div className="PublicFood_flex_box__element">
            <div className="PublicFood_flex_text">
                <h4>{t("PublicFood_subtitle3")}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle3_text1")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle3_text2")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle3_text3")}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img3}/>
            </div>
        </div>
        <div className="PublicFood_flex_box__element" id="PublicFood_box__element">
            <div className="PublicFood_flex_text">
                <h4>{t("PublicFood_subtitle4")}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle4_text1")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle4_text2")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle4_text3")}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img4}/>
            </div>
        </div>
        <div className="PublicFood_flex_box__element" >
            <div className="PublicFood_flex_text">
                <h4>{t("PublicFood_subtitle5")}</h4>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle5_text1")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle5_text2")}
                </p>
                <p>
                    <img alt="icon" src={icon} />
                    {t("subtitle5_text3")}
                </p>
            </div>
            <div className="PublicFood_flex_img">
                <img alt="" src={Img5}/>
            </div>
        </div>
      </div>
      </div>
      {/* System */}
      <div className="PublicFood_system_block">
        <h2 className="PublicFood_system_title">{t("System_title")}</h2>
        <p className="PublicFood_system_paragraf">{t("System_subtitle")}</p>
        <div className="PublicFood_flex_block PublicFood_flex_block1">
            <div className="PublicFood_system_item mini_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon1}/></div>
                <p className="PublicFood_miniText">{t("System_text1")}</p>
            </div>
            <div className="PublicFood_system_item mini_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon2}/></div>
                <p className="PublicFood_miniText">{t("System_text2")}</p>
            </div>
            <div className="PublicFood_system_item max_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon3}/></div>
                <p className="PublicFood_maxText">{t("System_text3")}</p>
            </div>
        </div>
        <div className=" PublicFood_flex_block PublicFood_flex_block2">
            <div className="PublicFood_system_item max_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon4}/></div>
                <p className="PublicFood_maxText">{t("System_text4")}</p>
            </div>
            <div className="PublicFood_system_item mini_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon5}/></div>
                <p className="PublicFood_miniText">{t("System_text5")}</p>
            </div>
            <div className="PublicFood_system_item max_item">
                <div className="PublicFood_systemIcon"><img  alt="icon" src={systemIcon6}/></div>
                <p className="PublicFood_maxText">{t("System_text6")}</p>
            </div>
        </div>
        <div className="PublicFood_flex_block PublicFood_flex_block3"> 
            <div className="PublicFood_system_item mini_item">
                <div className="PublicFood_systemIcon"><img  alt="icon" src={systemIcon7}/></div>
                <p className="PublicFood_miniText">{t("System_text7")}</p>
            </div>
            <div className="PublicFood_system_item max_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon8}/></div>
                <p className="PublicFood_maxText">{t("System_text8")}</p>
            </div>
        
          <div className="PublicFood_system_item mini_item">
                <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon9}/></div>
                <p className="PublicFood_miniText">{t("System_text9")}</p>
            </div>
        </div>
        <div  className="PublicFood_flex_block PublicFood_flex_block4">
            <div className="PublicFood_system_item max_item">
                    <div className="PublicFood_systemIcon"><img alt="icon" src={systemIcon10}/></div>
                    <p className="PublicFood_maxText">{t("System_text10")}</p>
            </div>
        </div>
      </div>

      {/*block 3  */}
      <div className="PublicFood_block">
        <div className="PublicFood_support_text support_text_block3">
          <h2>{t("Modern_equipment_and_software")}</h2>

          <p>
            <img alt="icon" src={icon} />
            {t("Modern_equipment_text1")}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {t("Modern_equipment_text2")}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {t("Modern_equipment_text3")}
          </p>
          <p>
            <img alt="icon" src={icon} />
            {t("Modern_equipment_text4")}
          </p>
          
        </div>
        <div className="PublicFood_comp">
          <img id="comp1" alt="phone" src={comp} />
          <img id="comp2" alt="phone" src={comp2} />
        </div>

        <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
      </div>
      {/* Advantages */}
      <div className="PublicFood_flex_wrapp">
            <div className="PublicFood_flex_row">
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon1} />
                    </div>
                    <h3>{t("Full_compliance")}</h3>
                    <p>{t("Full_compliance__text")}</p>
                    </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon2} />
                    </div>
                    <h3>{t("System_stability")}</h3>
                    <p>{t("System_stability__text")}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon3} />
                    </div>
                    <h3>{t("Reliability_and_speed")}</h3>
                    <p>{t("Reliability_and_speed_text")}</p>
                </div>
            </div>
            <div className="PublicFood_flex_row">
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon4} />
                    </div>
                    <h3>{t("Remote_control")}</h3>
                    <p>{t("Remote_control__text")}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon5} />
                    </div>
                    <h3>{t("Network_management")}</h3>
                    <p>{t("Network_management__text")}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon6} />
                    </div>
                    <h3>{t("Offline_work")}</h3>
                    <p>{t("Offline_work__text")}</p>
                </div>
            </div>
        </div>
     {/*block 5  */}
        <div className="PublicFood_block">
            <div className="PublicFood_support_text">
            <h2>{t("Contact_with")}</h2>
                <div className="PublicFood_text">
                        <div className="PublicFood_text_column" > 
                        <p>
                            {t("Accounting7")}
                        </p>
                        <p> 
                            {t("Accounting8")}
                        </p>
                        <p>
                            {t("Bowling_control_system")}
                        </p>
                        <p>
                            {t("Video_surveillance_system_Intellect")}
                        </p>
                        </div>
                        <div className="PublicFood_text_column">
                        <p>
                            {t("Restorun_table_reservation_system")}
                        </p>
                        <p> 
                            {t("Ticketing_system_Software_Premiere")}
                        </p>
                        <p>
                            {t("Hardware_software_complex")}
                        </p>
                        <p>
                            {t("Fitness_club_management")}
                        </p>
                        </div>
                </div>
            
            </div>
            <div className="PublicFood_iphone">
            <img id="iphone" alt="phone" src={iphone} />
            
            </div>

            <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
        </div>
      {/* sliding-line  */}
      {/*<SlideLine lang={lang}/>*/}
      <Swiper_carousel/>
      {/* Blue_block */}
       <div className="PublicFood_Blue_block">
             {/* <div> 
                    <img alt="bcg" src={blueBCG}/>
                        </div>  */}
            <p>{t("blue_block_text")}</p>
            <div className="PublicFood_button_wrap">
                <button className="Blue_block_button" onClick={()=>{
                   setIsTrue(true)
                }}>
                {t("Calculate_the_cost")}
                </button>
            </div>
        </div>
    </div>
  );
}

export default PublicFood;
