import Button from "../Button/Button"
import "./EntertainmentCenters.css"
import headerPic from "../../img/EntertainmentCenters/titlepic1.png";
import headerPic2 from "../../img/EntertainmentCenters/titlepic.png";
import bacground from "../../img/EntertainmentCenters/vector.png";
import robot from "../../img/EntertainmentCenters/robot.png"
import img1 from "../../img/EntertainmentCenters/block2_1.png"
import img2 from "../../img/EntertainmentCenters/block2_2.png"
import img3 from "../../img/EntertainmentCenters/block2_3.png"
import img4 from "../../img/EntertainmentCenters/block2_4.png"
import img5 from "../../img/EntertainmentCenters/block2_5.png"
import desctop from "../../img/EntertainmentCenters/desctop.png"
import icon1 from "../../img/EntertainmentCenters/icon1.png";
import icon2 from "../../img/EntertainmentCenters/icon2.png";
import icon3 from "../../img/EntertainmentCenters/icon3.png";
import icon4 from "../../img/EntertainmentCenters/icon4.png";
import icon5 from "../../img/EntertainmentCenters/icon5.png"
import {useTranslation} from "react-i18next";
import {Swiper_carousel} from "../Swiper_carousel/Swiper_carousel";


function EntertainmentCenters({setIsTrue}) {
    const { t } = useTranslation();
    return (
     <div>
      <div className="PublicFood_header">
        <div className="PublicFood_header_text">
          <h1>
            {t("EnterCenters_title")} <span>Game-Keeper</span> {t("ov")}
          </h1>
          <p className="EntertCenter_header_subtitle">{t("EnterCenters_subtitle")}</p>
        </div>
        <div className="Entert_center_flex_container">
          <div className="entert_grid_element">{t("EnterCenters_headertext1")}</div>
          <div className="entert_grid_element">{t("EnterCenters_headertext2")}</div>
          <div className="entert_grid_element">{t("EnterCenters_headertext3")}</div>
          <div className="entert_grid_element">{t("EnterCenters_headertext4")}</div>
        </div>
        <div className="Entert_center_flex_container">
          <div className="entert_grid_element">{t("EnterCenters_headertext5")}</div>
          <div className="entert_grid_element">{t("EnterCenters_headertext6")}</div>
        </div>
        <div className="Entert_center_flex_container2">
          <div className="entert_grid_element">{t("EnterCenters_headertext1")}</div>
          <div className="entert_grid_element">{t("EnterCenters_headertext2")}</div>
          <div className="entert_grid_element">{t("EnterCenters_headertext4")}</div>
          
        </div>
        <div className="Entert_center_flex_container2">
           <div className="entert_grid_element">{t("EnterCenters_headertext3")}</div>
          <div className="entert_grid_element">{t("EnterCenters_headertext5")}</div>
        </div>
        <div className="Entert_center_flex_container2">
          <div className="entert_grid_element">{t("EnterCenters_headertext6")}</div>
        </div>
       
        <div className="PublicFood_header_picture">
          <img id="PublicFood_pic_for_laptop" alt="pic" src={headerPic} />
          <img id="PublicFood_pic_for_mobile" alt="pic" src={headerPic2} />
          <div id="PublicFood_button_component">
            <Button setIsTrue={setIsTrue} />
          </div>
        </div>
      </div>
      {/* block 2 */}
      <div className="PublicFood_block2">
        <div className="PublicFood_block" id="PublicFood_block">
          <div className="PublicFood_support_text PublicFood_block2_text">
            <h2>{t("EntertCeters_block2_title")}</h2>
            <p> {t("EnterCenters_block2_text1")}</p>
            <p> {t("EnterCenters_block2_text2")}</p>
          </div>
          <div className="PublicFood_handAndPhone"> </div>
          <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
        </div>
        <img id="PublicFood_block2_img" alt="phone" src={robot} />
      </div>
      {/* block 3 */}
      <div>
        <div className="second_title">
          <h2 className="title_h2">{t("EnterCenters_block3_title")}</h2>
          <p className="subtitle_h2"> {t("EnterCenters_block3_text1")}</p>
        </div>
        <div className="categories_slide_wrapper">
          <div className="categories_flex">
            <div className="equipment_element_item">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("EnterCenters_block3_text2")}
                </p>
              </div>
              <div className="element_img EntertCenter_element_img">
                <img className="equipment_img" alt="img" src={img1} />
              </div>
            </div>
            <div className="equipment_element_item">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("EnterCenters_block3_text3")}
                </p>
              </div>
              <div className="element_img EntertCenter_element_img">
                <img className="equipment_img" alt="img" src={img2} />
              </div>
            </div>
            <div className="equipment_element_item">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("EnterCenters_block3_text4")}
                </p>
              </div>
              <div className="element_img EntertCenter_element_img">
                <img className="equipment_img" alt="img" src={img3} />
              </div>
            </div>
          </div>
          <div className="categories_flex" id="categories_flex">
            <div className="equipment_element_item">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("EnterCenters_block3_text5")}
                </p>
              </div>
              <div className="element_img EntertCenter_element_img" id="element_img">
                <img className="equipment_img" alt="img" src={img4} />
              </div>
            </div>
            <div className="equipment_element_item">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("EnterCenters_block3_text6")}
                </p>
              </div>
              <div className="element_img EntertCenter_element_img" id="element_img">
                <img className="equipment_img" alt="img" src={img5} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* block4 */}
      <div className="PublicFood_block">
            <div className="PublicFood_support_text">
            <h2>{t("Hotels_block3_title")}</h2>
                <div className="PublicFood_text">
                        <div className="Hotels_text_row" > 
                          <div className="Hotels_text_column" >
                            <h4>
                                r_keeper
                            </h4>
                            <h4>
                                {t("Hotels_block3_2")}
                            </h4>
                          </div>
                          <div className="Hotels_text_column" >
                           <p> 
                                {t("Hotels_block3_1")}
                            </p>
                            <p> 
                                {t("Hotels_block3_4")}
                            </p>
                           </div>
                           <div className="Hotels_text_column" >
                            <h4>
                                StoreHouse
                            </h4>
                            <h4>
                                Pooljet
                            </h4>
                           </div>
                           <div className="Hotels_text_column" > 
                            <p> 
                                {t("Hotels_block3_3")}
                            </p>
                            <p> 
                                {t("Hotels_block3_5")}
                            </p>
                        </div>
                    </div> 
                </div>
            </div>
            <div className="PublicFood_iphone Hotels_desctopImg">
            <img id="desktop" alt="phone" src={desctop} />
            </div>
            <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
      </div>
      {/* block 5 */}
      <div className="PublicFood_flex_wrapp">
            <div className="PublicFood_flex_row">
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon1} />
                    </div>
                    <h3>{t("EnterCenters_block5_1")}</h3>
                    <p>{t("EnterCenters_block5_2")}</p>
                    </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon2} />
                    </div>
                    <h3>{t("EnterCenters_block5_3")}</h3>
                    <p>{t("EnterCenters_block5_4")}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon3} />
                    </div>
                    <h3>{t("EnterCenters_block5_5")}</h3>
                    <p>{t("EnterCenters_block5_6")}</p>
                </div>
            </div>
            <div className="PublicFood_flex_row EntertCenter_flex_row">
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon4} />
                    </div>
                    <h3>{t("EnterCenters_block5_7")}</h3>
                    <p>{t("EnterCenters_block5_8")}</p>
                </div>
                <div className="PublicFood_element">
                    <div className="PublicFood_element_icon">
                        <img alt="icon" src={icon5} />
                    </div>
                    <h3>{t("EnterCenters_block5_9")}</h3>
                    <p>{t("EnterCenters_block5_10")}</p>
                </div>
         
            </div>
      </div>
         <Swiper_carousel/>
        {/* Blue_block */}
        <div className="PublicFood_Blue_block">
             {/* <div> 
                    <img alt="bcg" src={blueBCG}/>
                        </div>  */}
            <p>{t("blue_block_text6")}</p>
            <div className="PublicFood_button_wrap">
                <button className="Blue_block_button" onClick={()=>{
                  setIsTrue(true)
                }}>
                {t("Calculate_the_cost")}
                </button>
            </div>
        </div>
     </div>
    ) 
  }
  
  export default EntertainmentCenters