import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {StorageService} from "../../../Components/Crypto/Crypto";
import axios from "../../../Components/utils/axios";


export const fetchProduct = createAsyncThunk(
    'ProductState/fetchProduct',
    async () => {
        try {
            const { data } = await axios?.get('/product');
            return data;
        } catch (error) {
            console.log(error);
            throw error; // Ensures the error is thrown and can be caught in the `rejected` state in reducers
        }
    })

export const ProductState = createSlice({
    name: "Product",
    initialState: {
        Product_Data:[],
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProduct.pending, (state) => {
                state.Product_Data = 'loading';
                state.loading = true;
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                if(action.payload){
                    state.Product_Data = action.payload;
                    state.loading = null;
                }
            })
            .addCase(fetchProduct.rejected, (state) => {
                state.Product_Data = 'failed';
                state.loading = false;
            });
    }
});

export const Product_Item = (state) => state.product.Product_Data;

export default ProductState.reducer

