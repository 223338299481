import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Dialog,
    DialogFooter,
    DialogHeader,
    Typography
} from "@material-tailwind/react";
import {BadgeDefault} from "../Notifications/Notifications";
import {useDispatch, useSelector} from "react-redux";
import {fetchShop, setShop, setShopremove, Shop_Item} from "../../redux/features/shop/shopSlice";
import {IconClose} from "../../img/Component_svg/Component_svg";
import {fetchProduct, Product_Item} from "../../redux/features/product/productSlice";

const  DialogDefault = (elem) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(!open);

    const handleAddItem = (elem) => {
        dispatch(setShop(elem));
    };

    const handleRemoveItem = (_id) => {
        dispatch(setShopremove(_id));
    };

    return (
        <>
            <a className="flex justify-start items-end underline" style={{color:'#0000ffa1'}} onClick={handleOpen}>{t("See_more")}</a>
            <Dialog open={open} handler={handleOpen} className="w-full max-w-[0vw]">
                <DialogHeader className="DialogDefault_name flex justify-between p-2 border-b-purple-950">
                    <div>{elem.name}</div> <div className={"cursor-pointer"} onClick={() => setOpen(false)}><IconClose/></div>
                </DialogHeader>
                <Card className="printer_element_content w-full grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer rounded-none shadow-none px-5 py-5">
                    {/* Изображение */}
                    <CardHeader
                        shadow={false}
                        floated={false}
                        className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center mt-0"
                    >
                        <img
                             src={`${process.env.REACT_APP_API_PORT}/${elem.imgUrl}`}
                             alt="product"
                             className="w-full object-cover"
                        />
                    </CardHeader>

                    {/* Контент с текстом */}
                    <Typography color="gray" className="font-normal flex justify-center items-center">
                        {elem.type}
                    </Typography>
                </Card>

                <DialogFooter className='px-4 pb-4 pt-3'>
                    <Button variant="text" color="red" className="mr-1" onClick={() => {
                        handleRemoveItem(elem._id)
                        handleOpen()
                    }}>
                        <span>{t("delete")}</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => {
                        handleAddItem(elem)
                        handleOpen()
                    }}>
                        <span>{t("Add_to_Cart")}</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}

function Cashdrawer({ setIsTrue }) {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const Shop_data = useSelector(Shop_Item)
  const AllProduct_data = useSelector(Product_Item)

  useEffect(() => {
      dispatch(fetchShop())
      dispatch(fetchProduct())
  }, []);

  const Products = useCallback((Data) => {
        return <div className='printer_grid grid grid-cols-1 md:grid-cols-2 gap-6'>
            {Data?.Data?.length ? Data?.Data?.map((elem,index)=>{
                if(elem.lang === "ru" && localStorage.getItem("i18nextLng") === "ru" && elem.category === 5) {
                    return (
                        <div className='printer_element' key={index}>
                            <Typography variant="h6" color="gray" className="uppercase text-center p-2 text-2xl">
                                {elem.name}
                            </Typography>
                            <Card
                                className="printer_element_content shadow-none w-full max-w-md sm:max-w-lg md:max-w-full lg:max-w-3xl xl:max-w-4xl grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer px-5 py-3">
                                {/* Изображение */}
                                <CardHeader
                                    shadow={false}
                                    floated={false}
                                    className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center"
                                >
                                    <img
                                        src={`${process.env.REACT_APP_API_PORT}/${elem.imgUrl}`}
                                        alt="product"
                                        className="w-full object-cover"
                                    />
                                </CardHeader>

                                {/* Контент карточки */}
                                <div className="grid grid-rows-[auto_1fr_auto] gap-4">
                                    <CardBody className="p-0">
                                        <Typography variant="h4" color="blue-gray" className=" text-base ">
                                            <span>{elem.description_name}:</span> <span>{elem.description}</span>
                                        </Typography>
                                        <Typography color="gray" className="font-normal">
                                            <ul>
                                                {elem.description_name_1 && elem.description_1 ? <li><span>{elem.description_name_1}:</span> <span>{elem.description_1}</span></li> : null}
                                                {elem.description_name_2 && elem.description_2 ? <li><span>{elem.description_name_2}:</span> <span>{elem.description_2}</span></li> : null}
                                                {elem.description_name_3 && elem.description_3 ? <li><span>{elem.description_name_3}:</span> <span>{elem.description_3}</span></li> : null}
                                                {elem.description_name_4 && elem.description_4 ? <li><span>{elem.description_name_4}:</span> <span>{elem.description_4}</span></li> : null}
                                                {elem.description_name_5 && elem.description_5 ? <li><span>{elem.description_name_5}:</span> <span>{elem.description_5}</span></li> : null}
                                                {elem.description_name_6 && elem.description_6 ? <li><span>{elem.description_name_6}:</span> <span>{elem.description_6}</span></li> : null}
                                                {elem.description_name_7 && elem.description_7 ? <li><span>{elem.description_name_7}:</span> <span>{elem.description_7}</span></li> : null}
                                                {elem.description_name_8 && elem.description_8 ? <li><span>{elem.description_name_8}:</span> <span>{elem.description_8}</span></li> : null}
                                                {elem.description_name_9 && elem.description_9 ? <li><span>{elem.description_name_9}:</span> <span>{elem.description_9}</span></li> : null}
                                            </ul>
                                            {DialogDefault(elem)}
                                        </Typography>
                                    </CardBody>

                                    {/* Футер карточки с кнопкой */}
                                    <CardFooter className="w-full flex justify-start p-0">
                                        <Button
                                            ripple={false}
                                            fullWidth={true}
                                            className="CardFooter_button w-[100%] bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                            onClick={() => {
                                                handleAddItem(elem)
                                            }}
                                            style={Array.isArray(Shop_data) && Shop_data?.some(el => el._id === elem._id)
                                                ? {backgroundColor: "green", color: "#ffffff"}
                                                : {backgroundColor: "rgb(33 33 33 / 0.1)"}
                                            }
                                        >
                                            {t("Add_to_Cart")}
                                        </Button>
                                    </CardFooter>
                                </div>
                            </Card>
                        </div>
                    )
                }else if(elem.lang === "am" && localStorage.getItem("i18nextLng") === "hy" && elem.category === 5){
                    return (
                        <div className='printer_element' key={index}>
                            <Typography variant="h6" color="gray" className="uppercase text-center p-2 text-2xl">
                                {elem.name}
                            </Typography>
                            <Card
                                className="printer_element_content shadow-none w-full max-w-md sm:max-w-lg md:max-w-full lg:max-w-3xl xl:max-w-4xl grid grid-cols-1 md:grid-cols-[2fr_3fr] gap-4 cursor-pointer px-5 py-3">
                                {/* Изображение */}
                                <CardHeader
                                    shadow={false}
                                    floated={false}
                                    className="printer_element_img rounded-t-lg md:rounded-l-lg md:rounded-tr-none overflow-hidden flex justify-center items-center"
                                >
                                    <img
                                        src={`${process.env.REACT_APP_API_PORT}/${elem.imgUrl}`}
                                        alt="product"
                                        className="w-full object-cover"
                                    />
                                </CardHeader>

                                {/* Контент карточки */}
                                <div className="grid grid-rows-[auto_1fr_auto] gap-4">
                                    <CardBody className="p-0">
                                        <Typography variant="h4" color="blue-gray" className=" text-base ">
                                            <span>{elem.description_name}:</span> <span>{elem.description}</span>
                                        </Typography>
                                        <Typography color="gray" className="font-normal">
                                            <ul>
                                                <ul>
                                                    {elem.description_name_1 && elem.description_1 ? <li><span>{elem.description_name_1}:</span> <span>{elem.description_1}</span></li> : null}
                                                    {elem.description_name_2 && elem.description_2 ? <li><span>{elem.description_name_2}:</span> <span>{elem.description_2}</span></li> : null}
                                                    {elem.description_name_3 && elem.description_3 ? <li><span>{elem.description_name_3}:</span> <span>{elem.description_3}</span></li> : null}
                                                    {elem.description_name_4 && elem.description_4 ? <li><span>{elem.description_name_4}:</span> <span>{elem.description_4}</span></li> : null}
                                                    {elem.description_name_5 && elem.description_5 ? <li><span>{elem.description_name_5}:</span> <span>{elem.description_5}</span></li> : null}
                                                    {elem.description_name_6 && elem.description_6 ? <li><span>{elem.description_name_6}:</span> <span>{elem.description_6}</span></li> : null}
                                                    {elem.description_name_7 && elem.description_7 ? <li><span>{elem.description_name_7}:</span> <span>{elem.description_7}</span></li> : null}
                                                    {elem.description_name_8 && elem.description_8 ? <li><span>{elem.description_name_8}:</span> <span>{elem.description_8}</span></li> : null}
                                                    {elem.description_name_9 && elem.description_9 ? <li><span>{elem.description_name_9}:</span> <span>{elem.description_9}</span></li> : null}
                                                </ul>
                                            </ul>
                                            {DialogDefault(elem)}
                                        </Typography>
                                    </CardBody>

                                    {/* Футер карточки с кнопкой */}
                                    <CardFooter className="w-full flex justify-start p-0">
                                        <Button
                                            ripple={false}
                                            fullWidth={true}
                                            className="CardFooter_button w-[100%] bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                            onClick={() => {
                                                handleAddItem(elem)
                                            }}
                                            style={Array.isArray(Shop_data) && Shop_data?.some(el => el._id === elem._id)
                                                ? {backgroundColor: "green", color: "#ffffff"}
                                                : {backgroundColor: "rgb(33 33 33 / 0.1)"}
                                            }
                                        >
                                            {t("Add_to_Cart")}
                                        </Button>
                                    </CardFooter>
                                </div>
                            </Card>
                        </div>
                    )
                }
                })
            : null}
        </div>
    },[AllProduct_data,Shop_data])

  const handleAddItem = (elem) => {
      dispatch(setShop(elem));
  };

  return (
    <div  id='printers'>
        <div className={'flex justify-between items-center mb-5'}>
            <h2> {t("Cash_drawers")}</h2>
            <BadgeDefault Shop_data={Shop_data}  setIsTrue={setIsTrue} />
        </div>
            <Products Data = {AllProduct_data?.ProductS} />
    </div>
  )
}

export default Cashdrawer
