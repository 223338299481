import { configureStore } from '@reduxjs/toolkit'
import authSlice from './features/auth/authSlice'
import postSlice from './features/post/postSlice'
import commentSlice from './features/comment/commentSlice'
import shopSlice from './features/shop/shopSlice'
import productSlice from "./features/product/productSlice";
import shopBlog from "./features/blog/shopBlog";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        post: postSlice,
        product: productSlice,
        comment: commentSlice,
        shop:shopSlice,
        blog:shopBlog,
    },
})
