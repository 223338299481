import './PostList.css'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect} from "react";
import {Blog_Item, fetchBlog} from "../../../redux/features/blog/shopBlog";
import {PostItem} from "../PostItem/PostItem";

function PostList() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const Blog = useSelector(Blog_Item)

    useEffect(() => {
        dispatch(fetchBlog())
    }, [dispatch])

    let Item = useCallback(({Date}) =>{
        return <div className={"py-5"}>
            <section className={"Item"}>
                   <div className='w-[100%] flex justify-center gap-8'>
                          <div className='w-[100%] flex flex-col gap-10'>
                              <PostItem post={Date} />
                          </div>
                   </div>
            </section>

            <div className="p-6 mt-8 mx-auto bg-gray-100 rounded-lg shadow-lg">
                <div className="mb-6 text-start">
                    <p className="text-lg font-medium text-gray-800">{Date.description}</p>
                </div>

                {Date.description_name_1 && Date.description_1 ? <div className="mt-6 p-4 bg-white rounded-lg shadow">
                    <h2 className="text-xl font-semibold text-blue-600 mb-4">{Date.description_name_1}</h2>
                    <p className="text-gray-700">{Date.description_1}</p>
                </div> : null}

                {Date.description_name_2 && Date.description_2 ? <div className="mt-6 p-4 bg-white rounded-lg shadow">
                    <h2 className="text-xl font-semibold text-blue-600 mb-4">{Date.description_name_2}</h2>
                    <p className="text-gray-700">{Date.description_2}</p>
                </div> : null}

                {Date.description_name_3 && Date.description_3 ? <div className="mt-6 p-4 bg-white rounded-lg shadow">
                    <h2 className="text-xl font-semibold text-blue-600 mb-4">{Date.description_name_3}</h2>
                    <p className="text-gray-700">{Date.description_3}</p>
                </div> : null}

                {Date.description_name_4 && Date.description_4 ? <div className="mt-6 p-4 bg-white rounded-lg shadow">
                    <h2 className="text-xl font-semibold text-blue-600 mb-4">{Date.description_name_4}</h2>
                    <p className="text-gray-700">{Date.description_4}</p>
                </div> : null}

                {Date.description_name_5 && Date.description_5 ? <div className="mt-6 p-4 bg-white rounded-lg shadow">
                    <h2 className="text-xl font-semibold text-blue-600 mb-4">{Date.description_name_5}</h2>
                    <p className="text-gray-700">{Date.description_5}</p>
                </div> : null}
            </div>

        </div>
    },[Blog])

    return <div>
        <Item Date={Blog} />
    </div>
}

export default PostList