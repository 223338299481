import "./MainPage.css";
import Button from "../Button/Button";
import arrow from "../../img/IconarrowBlue.svg";
import HeadImg from "../../img/Group 1titleComp.png";
import HeadImg2 from "../../img/Component 1111 1.png";
import coctail from "../../img/glass-cocktailcup.svg";
import restorant from "../../img/restorant.jpeg";
import fork from "../../img/cutlery-4 fork.svg";
import burger from "../../img/fastfood 1_2.png";
import bigBed from "../../img/Bedbed_1.jpg";
import bed from "../../img/bed-3bed.svg";
import playGamePad from "../../img/playpad.svg";
import playGame from "../../img/playGame_1.jpg";
import packet from "../../img/packet_1.jpg";
import buscket from "../../img/shopping-cartbuscket.svg";
import popcorn from "../../img/content_offer.jpg";
import reel from "../../img/film-reelfilm.svg";
import maxphone from "../../img/mobile_restaurant.jpg";
import miniphone from "../../img/Icon.svg";
import Gil1 from "../../img/gantel.jpg";
import Gil2 from "../../img/IconGil.svg";
import icon1 from "../../img/Group 48icon1.svg";
import icon2 from "../../img/Group 48icon2.svg";
import icon3 from "../../img/Group 48icon3.svg";
import icon4 from "../../img/Group 48icon4.svg";
import icon5 from "../../img/Group 48icon5.svg";
import phone from "../../img/Component 1fffff 1.png";
import phone2 from "../../img/Component 2ggggg 1.png";
import bacground from "../../img/Groupbcg.svg";
import POS from "../../img/POS.png";
import arrow_right from "../../img/arrow-rightarrow-right.svg";
import greyArrow from "../../img/arrow-rightarrowGrey.svg";
import { useNavigate } from "react-router-dom";
import scanner from "../../img/scanner.png"
import printer1 from "../../img/products/printer1.png"
import {useTranslation} from "react-i18next";
import {Swiper_carousel} from "../Swiper_carousel/Swiper_carousel";

function MainPage({setIsTrue}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="MainPage_main_wrapper">
      <div className="MainPage_main_section">
        <div className="MainPage_main_section_text">
          <h1>{t("Title1")}</h1>
          <div className="MainPage_buttons_flex">
            <div className="MainPage_title_button">
              <img alt="" src={arrow} />
              <p>{t("Title_button_1")}</p>
            </div>
            <div className="MainPage_title_button">
              <img alt="" src={arrow} />
              <p>{t("Title_button_2")}</p>
            </div>
          </div>
          <Button setIsTrue={setIsTrue}/>

        </div>
        <img id="MainPage_HeadImg" alt="MainPage_headImg" src={HeadImg} />
        {/* img for mobile version */}
        <div id="MainPage_HeadImg2">
          <img alt="MainPage_headImg2" src={HeadImg2} />
        </div>
      </div>

      {/* 2 block */}
      <div className="MainPage_flex_container">
        <h2>{t("Title2")}</h2>
        <div className="MainPage_flex_items">
          <div className="MainPage_flex_item"
                onClick={() => {
                  navigate("PublicFood");
                }}
          >
            <div className="MainPage_flex_item__title">
              <p>{t("Conceptual_Restaurant")}</p>
              <img alt="MainPage_a" src={coctail} />
            </div>
            <img alt="MainPage_restorant" src={restorant} className="MainPage_product_img" />
          </div>
          <div className="MainPage_flex_item"
              onClick={() => {
                navigate("FastFood");
              }}
          >
            <div className="MainPage_flex_item__title">
              <p>{t("FastFood")}</p>
              <img alt="MainPage_a" src={fork} />
            </div>
            <img alt="MainPage_restorant" src={burger} className="MainPage_product_img" />
          </div>
          <div className="MainPage_flex_item" 
              onClick={() => {
                navigate("Hotels");
              }}
          >
            <div className="MainPage_flex_item__title">
              <p>{t("Hotel")}</p>
              <img alt="MainPage_a" src={bed} />
            </div>
            <img alt="MainPage_restorant" src={bigBed} className="MainPage_product_img" />
          </div>
          <div className="MainPage_flex_item" 
                onClick={() => {
                  navigate("EntertainmentCenters");
                }}>
            <div className="MainPage_flex_item__title">
              <p>{t("Entertainment_Center")}</p>
              <img alt="MainPage_a" src={playGamePad} />
            </div>
            <img alt="MainPage_restorant" src={playGame} className="MainPage_product_img" />
          </div>
        </div>
        <div className="MainPage_flex_items">
          <div className="MainPage_flex_item"
           onClick={() => {
            navigate("Retail");
          }}
          >
            <div className="MainPage_flex_item__title">
              <p>{t("Retail_outlet")}</p>
              <img alt="MainPage_a" src={buscket} />
            </div>
            <img alt="MainPage_restorant" src={packet} className="MainPage_product_img" />
          </div>
          <div className="MainPage_flex_item"
           onClick={() => {
            navigate("Cinema");
          }}
          >
            <div className="MainPage_flex_item__title">
              <p>{t("Cinema")}</p>
              <img alt="MainPage_a" src={reel} />
            </div>
            <img alt="MainPage_restorant" src={popcorn} className="MainPage_product_img" />
          </div>
          <div className="MainPage_flex_item"
            onClick={() => {
              navigate("MobileApp");
            }}
          >
            <div className="MainPage_flex_item__title">
              <p>{t("Restaurant_app")}</p>
              <img alt="MainPage_a" src={miniphone} />
            </div>
            <img alt="MainPage_restorant" src={maxphone} className="MainPage_product_img" />
          </div>
          <div className="MainPage_flex_item" 
           onClick={() => {
            navigate("Fitness");
          }}
          >
            <div className="MainPage_flex_item__title">
              <p>{t("Sports_facility")}</p>
              <img alt="MainPage_a" src={Gil2} />
            </div>
            <img alt="MainPage_restorant" src={Gil1} className="MainPage_product_img" />
          </div>
        </div>
      </div>

      {/* grid  */}
      <div className="MainPage_grid">
        <h2 className="MainPage_title_h2">{t("All_business_management_features")}</h2>
        <div className="MainPage_grid_wrapper1">
          <div className="MainPage_grid_item">
            <div className="MainPage_item_name">
              <img alt="MainPage_icon1" src={icon1} />
              <h3>{t("Order_management")}</h3>
            </div>
            <p>{t("Order_management_text")}</p>
          </div>
          <div className="MainPage_grid_item">
            <div className="MainPage_item_name">
              <img alt="MainPage_icon1" src={icon2} />
              <h3>{t("Work_with_clients")}</h3>
            </div>
            <p>{t("Work_with_clients_text")}</p>
          </div>
          <div className="MainPage_grid_item">
            <div className="MainPage_item_name">
              <img alt="MainPage_icon1" src={icon3} />
              <h3>{t("Financial_management")}</h3>
            </div>
            <p>{t("Financial_management_text")}</p>
          </div>
        </div>
        <div className="MainPage_grid_wrapper2">
          <div className="MainPage_grid_item">
            <div className="MainPage_item_name">
              <img alt="MainPage_icon1" src={icon4} />
              <h3>{t("Retail_sales")}</h3>
            </div>
            <p>{t("Retail_sales_text")}</p>
          </div>
          <div className="MainPage_grid_item">
            <div className="MainPage_item_name">
              <img alt="MainPage_icon1" src={icon5} />
              <h3>{t("Inventory_control")}</h3>
            </div>
            <p>{t("Inventory_control_text")}</p>
          </div>
        </div>
      </div>
      <Swiper_carousel/>
      {/* flex numeric block */}
      <div>
        <h2 className="MainPage_chaos_title">{t("Chaos")}</h2>
        <div className="MainPage_flex_box">
          <div className="MainPage_flex_elem">
            <div className="MainPage_flex_number">
              <p>1</p>
            </div>
            <p className="MainPage_flex_text">
              {t("Chaos_text_1")}
            </p>
          </div>
          <div className="MainPage_flex_elem">
            <div className="MainPage_flex_number">
              <p>2</p>
            </div>
            <p className="MainPage_flex_text">
              {t("Chaos_text_2")}
            </p>
          </div>
        </div>
      </div>
      {/* support part */}
      <div className="MainPage_support_block">
        <div className="MainPage_support_text">
          <h2>{t("Support_at_any_time_of_the_day")}</h2>
          <p>{t("Support_text1")}</p>
          <p>{t("Support_text2")}</p>
        </div>
        <div className="MainPage_phone1">
          <img alt="MainPage_phone" src={phone2} />
        </div>
        <div className="MainPage_phone2">
          <img alt="MainPage_phone" src={phone} />
        </div>
        <img id="MainPage_BCGimg" alt="MainPage_bcg" src={bacground} />
      </div>

      {/* Popular_equipment_categories */}
      <div>
        <h2 className="MainPage_title_h2">{t("Popular_equipment_categories")}</h2>
        <div id="MainPage_hidden_block">
          <p>{t("scroll_through")}</p>
          <img alt="MainPage_arrow" src={greyArrow} />
        </div>
        <div className="MainPage_categories_slide_wrapper">
          <div className="MainPage_categories_flex">
            <div className="MainPage_equipment_element" onClick={()=>{navigate("Hardware/Posterminals")}}>
              <p className="MainPage_equipment_text">
                {t("POS")}
                <img alt="MainPage_arrow" src={arrow_right} />
              </p>
              <img className="MainPage_equipment_img" alt="MainPage_img" src={POS} />
            </div>

            <div className="MainPage_equipment_element" onClick={()=>{navigate("Hardware/Printers")}}>
              <p className="MainPage_equipment_text">
                {t("Printer")}
                <img alt="MainPage_arrow" src={arrow_right} />
              </p>
              <img className="MainPage_equipment_img" alt="MainPage_img" src={printer1} />
            </div>
            <div className="MainPage_equipment_element" onClick={()=>{navigate("Hardware/Retail_store_equipment")}}>
              <p className="MainPage_equipment_text">
                {t("Scanners")}
                <img alt="MainPage_arrow" src={arrow_right} />
              </p>
              <img className="MainPage_equipment_img" alt="MainPage_img" src={scanner} />
            </div>
          </div>
        </div>
      </div>
      {/* Blue_block */}
      <div className="MainPage_Blue_block">
        <p>{t("Lets_implement_automation")}</p>
        <div className="MainPage_button_wrap">
          <button className="Blue_block_button" onClick={()=>{setIsTrue(true)}}>{t("Increase_profit")}</button>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
