import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {StorageService} from "../../../Components/Crypto/Crypto";


export const fetchBlog = createAsyncThunk(
    'BlogState/fetchBlog',
    async () => {
        let Blog = StorageService?.getStorage('Blog_Data')
        try {
            return Blog ? Blog : []

        }catch (error) {
            throw error; // Ensures the error is thrown and can be caught in the `rejected` state in reducers
        }
    })

export const BlogState = createSlice({
    name: "blog",
    initialState: {
        Blog_Data:{},
    },
    reducers: {
        setBlog:(state,{payload})=> {
            state.Blog_Data = payload
            StorageService.setStorage("Blog_Data" ,state.Blog_Data)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlog.pending, (state) => {
                state.Blog_Data = 'loading';
                state.loading = true;
            })
            .addCase(fetchBlog.fulfilled, (state, action) => {
                if(action.payload){
                    state.Blog_Data = action.payload;
                    state.loading = null;
                }
            })
            .addCase(fetchBlog.rejected, (state) => {
                state.Blog_Data = 'failed';
                state.loading = false;
            });
    }
});

export const Blog_Item = (state) => state.blog.Blog_Data;

export const {setBlog} = BlogState.actions;

export default BlogState.reducer
