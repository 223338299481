import React from 'react'
import './PostItem.css'
import { AiFillEye, AiOutlineMessage } from 'react-icons/ai'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'

export const PostItem = ({ post }) => {
    if(post){
        return (
            <div className='blog_Item flex flex-col basis-1 flex-grow'>
                <div
                    id={"blog_Item_img"}
                    className={
                        post?.imgUrl ? 'flex rouded-sm h-80' : 'flex rounded-sm'
                    }
                >
                    {post?.imgUrl && (
                        <img
                            src={`${process.env.REACT_APP_API_PORT}/${post.imgUrl}`}
                            alt='img'
                            className='object-cover w-full'
                        />
                    )}
                </div>
                <div className={"blog_Item_list"}>
                    <div className={"blog_Item_content"}>
                        <div className='blog_Item_content_title'>{post.title}</div>
                        <div className='blog_Item_content_text'>{post.text}</div>
                        <div className='blog_Item_content_data'>
                            <Moment date={post.createdAt} format='D MMM YYYY' />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
